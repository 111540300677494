<template>
   <v-row justify="center" class="text-center">
        <v-overlay :value="overlay" :absolute="absolute" :fixed="fixed" :opacity="opacity" :z-index="zindex">
            <div class="display-2 my-12 mx-auto pa-auto text-center" align="center"> 
                <div class=" ma-auto">{{$t("waitingforapproval")}}</div>
                <div class="dot-flashing ma-auto"></div>
            </div>
            <div v-if="$checkPermission('Tasks.Approve')">
                <v-textarea
                  grow
                  outlined
                  v-model="reason"
                  :label="$t('Reason')"
                  :placeholder="$t('Reason')"
                ></v-textarea>
                <v-btn class="ma-1" light depressed @click="reject">
                    {{$t('Reject')}}
                    <v-icon right>
                      mdi-cancel
                    </v-icon>
                </v-btn>
                <v-btn class="ma-1 custom-color-accent" depressed @click="approve"> 
                    {{$t('approve')}} 
                    <v-icon right>
                       mdi-checkbox-marked-circle
                    </v-icon>   
                </v-btn>
            </div>
        </v-overlay>
    </v-row>
</template>
<script>

export default {
  props:['absolute','fixed'],
  data () {
    return {
        zindex: 3,
        opacity: 0.5,
        reason: "",
        overlay: true,
        hasPermission:true,
    }
  },
  methods: {
   approve(){
   this.$emit('approve',this.reason);
   },
   reject(){
    this.$emit('reject',this.reason);
   },
  },
  watch:{
   
  },
}
</script>
<style scoped>

.dot-flashing {
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: #14535c;
  color: #14535c;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: .5s;
}

.dot-flashing::before, .dot-flashing::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -23px;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: #14535c;
  color: #14535c;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 23px;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: #14535c;
  color: #14535c;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dotFlashing {
  0% {
    background-color: #14535c;
  }
  50%,
  100% {
    background-color: #ebe6ff77;
  }
}
</style>