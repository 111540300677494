<template>
    <div>
        <fileinputdrop class="my-2" 
            :allowedTypes="allowedTypes"
            :allowedSize="allowedSize"
            :filesprop="files"
            :multiple="multiple"
            @upload-file="uploadFile"
        ></fileinputdrop>
        <div class="pa-0 ma-0 my-2">
          <v-row class="pa-0 ma-0">
              <v-col
                  class="pa-0 ma-0 text-right"
                  cols="12">
                  <v-btn :disabled="!this.files.length || !$checkPermission('Attachements.Upload') || task.type==2" class="pa-2 ma-0 teal--text" outlined @click="save">{{$t("Upload")}}</v-btn>
              </v-col>
          </v-row>
          <v-row v-if="task.attachment_id">
              <v-col
                  cols="12"
                  sm="6"
                  md="4"
                  lg="3"
                  v-for="(item,i)  in task.attachment_id.split(',')"
                  :key="i"
              >
                  <v-input
                  class="my-border customlightgray ma-0 pa-3"
                  :messages="bytesToSize(item)"
                  prepend-icon="mdi-file"
                  >
                    <v-list-item-subtitle>{{getAttachment(item) ? getAttachment(item)['AttachmentFileName'] : ""}}</v-list-item-subtitle>
                    <v-icon class="ml-auto clickable" @click="downloadAttachment(getAttachment(item))">mdi-cloud-download</v-icon>
                    <v-icon class="ml-2 clickable" v-if="$checkPermission('Tasks.Update')" @click="removeAttachment(item)"
                      >mdi-delete</v-icon
                  >
                  </v-input>
              </v-col>
          </v-row>
        </div>
    </div>
</template>
<script>
import fileinputdrop from "../FileInputDrop";
import {mapActions, mapState} from "vuex";
export default {
  props:['task'],
  components:{
    fileinputdrop
  },
  data() {
    return {
      allowedTypes: "text/css, text/csv, text/html, text/calendar, video/mpeg, video/3gpp, video/3gpp2, video/x-flv, video/mp4, video/3gpp, video/MP2T, video/quicktime, video/x-msvideo, video/x-matroska, video/x-ms-wmv, text/javascript, application/java-archive, application/x-mpegURL, application/docx, application/gzip, application/zip, application/x-7z-compressed, application/rtf, application/epub+zip, application/vnd.rar, application/vnd.debian.binary-package, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.ms-fontobject, application/doc, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/pdf, application/sql, image/apng, image/bmp, image/gif, image/jpeg, image/pjpeg, image/png, image/svg+xml, image/tiff, image/webp, image/x-icon, image/jpg, image/vnd.microsoft.icon, image/svg+xml",
      allowedSize: "15000000",
      multiple:true,
      currentAttachment:[],
      files: [],
    };
  },
  computed:{
    ...mapState('attachment', {
      attachments: 'attachments'
    })
  },
  methods:{
    uploadFile(files) {
      if(this.$checkPermission('Attachements.Upload'))
        this.files = files;
      else{
          this.$root.$emit("callAlert", {
            text: this.$t("notallowed"),
            type: "dark",
            alert: true
          });
        this.files = []}
    },
    getAttachment(id){
      return this.attachments.filter(a => a.id  === parseInt(id) )[0];
    },
    bytesToSize(id) {
      let bytes = this.attachments.filter(a => a.id  === parseInt(id) ).map(b=> b.AttachmentSize)[0]
      var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      if (bytes == 0) return "0 Byte";
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
    },
    removeAttachment(id){
      let data = { taskid: this.task.id, attachmentId: id};
      this.$store.dispatch('task/deleteTaskAttachment',data).then(()=>{
          this.$store.dispatch('attachment/directAttachDelete',id);
          this.$store.dispatch('attachment/retriveAttachments');
      })
    },
    downloadAttachment(data){
      this.$store.dispatch('attachment/downloadAttachment',data)
    },
    async save() {
      const formData = new FormData();
      formData.append("id", this.task.id);
      formData.append("title", this.task.title);
      formData.append("request_id", this.task.request_id);
      formData.append("arrLength", this.files.length);
      formData.append("attachment_id", this.task.attachment_id ? this.task.attachment_id : '' );
      for (let index = 0; index < this.files.length; index++) {
        let file = this.files[index];
        formData.append("data[" + index + "]", file);
      }
      this.files = [];
      this.updateTask(formData).then(()=>{
        this.$store.dispatch('attachment/retriveAttachments');
      });
    },
    ...mapActions('task',['updateTask'])
  }
}
</script>