<template>
    <div>
        <v-timeline
            dense
            clipped
            >
                <v-row class="pa-0 ma-0 d-flex">
                  <v-col cols="9" sm="10" lg="11" order="1" order-sm="1" class="pa-0 ma-auto justify-center">
                    <v-timeline-item
                        fill-dot
                        :class="task.task_log.length>0?'pa-0 ma-0 mb-6':'pa-0 ma-0'"
                        color="gray"
                        large
                    >
                      <template v-slot:icon>
                        <v-tooltip right>
                          <template v-slot:activator="{ on, attrs }">
                              <span v-if="user" v-bind="attrs" v-on="on" class="text-uppercase">{{ user.first_name.charAt(0) }}{{ user.last_name.charAt(0)}}</span>
                          </template>
                          <span>{{user.first_name}} {{user.last_name}}</span>
                        </v-tooltip>
                      </template>
                      <v-text-field
                        v-model="input"
                        hide-details
                        single-line 
                        class="customlightgray ma-auto pa-0"
                        outlined
                        :placeholder="$t('Leavecomment')"
                        @keydown.enter="comment"
                        >
                      </v-text-field>
                    </v-timeline-item>
                  </v-col>
                  <v-col cols="3" sm="2" lg="1" order="2" order-sm="2" class="pa-0 pl-1 ma-0 mt-3 text-right justify-center">
                    <v-btn class="ma-auto pa-0 custom-color-accent" style="width:100%;" depressed 
                      :disabled="input==''"
                      @click="comment"
                      >
                      {{$t("Post")}}
                    </v-btn>
                  </v-col>
                </v-row>
                
            <v-slide-x-transition
                group >
                <v-timeline-item
                v-for="event in task.task_log"
                :key="event.id"
                class="ma-0 pa-0 mb-3 "
                fill-dot
                large
                color="custom-color-accent"
                >
                    <v-row justify="space-between">
                        <v-col cols="9" sm="9" md="9" lg="10">
                          <div>
                            <b>@{{event.user_details.username}} </b>
                            <span>{{lang=='en'?  event.log_en :  event.log_fr}}</span>
                          </div>
                        </v-col>
                        <v-col class="text-right" cols="3" sm="3" md="3" lg="2">{{event.created_at | fromNow}}</v-col>
                    </v-row>
                    <template v-slot:icon>
                        
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on" class="text-uppercase white--text ">{{ event.user_details.first_name.charAt(0) }}{{ event.user_details.last_name.charAt(0)}}</span>
                        </template>
                        <span>{{event.user_details.first_name}} {{event.user_details.last_name}}</span>
                      </v-tooltip>
                    </template>
                </v-timeline-item>
            </v-slide-x-transition>
        </v-timeline>
    </div>
</template>
<script>
import {mapActions, mapState} from "vuex";

  export default {
    props:['task'],
    data: () => ({
      input: '',
      nonce: 3,
    }),

    computed: {
      timeline () {
        return this.task.task_log
      }, 
       lang() {
       return localStorage.getItem('language');
      }, 
      ...mapState(['user']),
    },

    created() {
        this.$store.dispatch("retriveUserInfo");
    },
    methods: {
      ...mapActions('task',['postTaskLog']),
      comment () {
        this.input!=''?
        this.postTaskLog({
          task_id: this.task.id,
          log_en: this.input,
          log_fr: this.input,
          user_id: this.user.id
        }):'';
        this.input = ''
      },
    },
  }
</script>