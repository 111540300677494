<template>
    <v-card v-if="task" class="pa-0 ma-0 elevation-0 transparent">
      <approveoverlay v-if="false" :absolute="false" :fixed="true" @reject="reject" @approve="approve"></approveoverlay>
      <v-alert 
          class="alert custom-color-accent ma-0 mb-1 py-2"
          :value="task.type==2"  
          elevation="0"
        >
        <span class="text-left">{{$t("waitingforapproval")}}</span>
        <v-progress-linear
          indeterminate
          absolute
          bottom
          color="white"
          class="pa-0 ma-0"
        ></v-progress-linear>
      </v-alert>
      <div class="pa-3 white my-border" :style="task.type==2?'min-height:calc(100vh - 160px)':'min-height:calc(100vh - 130px)'">
        <v-row class="pa-0 pb-2 ma-0 text-left ">
            <v-col cols="12" sm="6" class="text-left pa-0 ma-auto" v-if="task" order="2" order-sm="2">
                <span class="text--secondary pa-auto ma-auto justify-center float-left text-left d-inline">
                  <span class="text-uppercase mr-1 font-weight-medium text--secondary " style="font-size: 15px !important;">{{task.title}}</span>
                  <sub class="text-uppercase customlightgray pa-1 font-weight-bold text--disabled overline">{{getLabelLookup('TaskType',task.type)[0]}}</sub>
                  <sub class="font-weight-bold text--disabled ml-1 ma-0">{{ task.status ? $t("Opened") : $t("Closed") }} {{ task.status ? task.created_at : task.date_completed | fromNow }}</sub>
                </span>
            </v-col>
            <v-col cols="12" sm="6" class="text-right justify-end d-flex pa-0 ma-0 ma-md-auto" v-if="task" order="1" order-sm="2">
              <div class="my-auto">
                <v-chip class="mr-1" color="dark">{{getLabelLookup('TaskStatus',task.status)[0]}}</v-chip>
                <v-chip class="mr-1" v-if="JSON.parse( task.label ).length>0" :color="setcolor(JSON.parse( task.label )[0])">{{getLabelLookup('TaskLabel',JSON.parse( task.label )[0])[0]}}</v-chip>
                <v-chip class="mr-1" v-if="JSON.parse( task.label ).length>1">+{{JSON.parse( task.label ).length-1}}</v-chip>
              </div>
              <div class="my-auto" v-if="$checkPermission('Tasks.Update')">
                <v-btn class="ma-auto" depressed v-if="task.status" :disabled="task.type==2" @click="close()">{{$t("Close")}}</v-btn>
                <v-btn class="ma-auto" depressed v-else :disabled="task.type==2" @click="reopenTask()">{{$t("Reopen")}}</v-btn>
              </div>
            </v-col>
        </v-row>
        <v-row class="my-0 px-2">
          <v-col cols="12" class="ma-0 pa-1 task-column">
            <div class="d-flex text-left customlightgray my-border pa-0 h-100 ma-0">
              <v-row class="pa-0 mx-3 my-auto">
                <v-col cols="4" md="2" class="pa-auto ma-0">
                  <span class="d-flex pr-2 text--disabled text-capitalize">{{ $t('datecreated') }}: </span>
                </v-col>
                <v-col cols="8" md="10" class="pa-auto ma-0 text--secondary text-uppercase">
                  <label v-if="task.created_at">
                      {{ task.created_at | formatDate}}
                    </label>
                    <label v-else>
                      -------------
                    </label>
                </v-col>
                <v-spacer></v-spacer>
              </v-row>
            </div>
          </v-col>
          <v-col cols="12" class="ma-0 pa-1 task-column">
            <div class="d-flex text-left customlightgray my-border pa-0 h-100 ma-0">
              <v-row class="pa-0 mx-3 my-auto">
                <v-col cols="4" md="2" class="pa-auto ma-0">
                  <span class="d-flex pr-2 text--disabled text-capitalize">{{ $t('datedue') }}: </span>
                </v-col>
                <v-col v-if="update_date_due && $checkPermission('Tasks.Update') && task.type!=2" cols="8" md="10" class="pa-1 ma-0">
                  <v-menu
                    v-model="due"
                    :close-on-content-click="true"
                    :nudge-right="40"
                    class="ma-0 pa-0"
                    hide-details
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        append-icon="mdi-close" append-outer-icon="mdi-check" @click:append="update_date_due = false,modify=false"
                        @click:append-outer="save('update_date_due')"
                        dense
                        v-model="due_date"
                        class="ma-0 pa-0 pr-2 white my-border" flat solo
                        v-on="on"
                        hide-details
                      ></v-text-field>
                    </template>
                    <v-date-picker dark :min="nowDate" v-model="due_date" @input="due = false"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col v-else cols="8" md="10" class="pa-auto ma-0">
                  <v-tooltip content-class='custom-tooltip' right>
                    <template v-slot:activator="{ on }">
                      <label v-if="task.due_date" v-on="on" @click="modify = true , update_date_due=true" class="clickable ">
                          {{ task.due_date | formatDate }}
                      </label>
                      <label v-else v-on="on" @click="modify = true , update_date_on_request=true" class="clickable ">
                        -------------
                      </label>
                    </template>
                    <v-btn v-if="$checkPermission('Tasks.Update') && task.type!=2" @click="modify = true , update_date_due=true" icon color="teal">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </v-tooltip>
                </v-col>
              </v-row>
            </div>
          </v-col>
          <v-col cols="12" class="ma-0 pa-1 task-column">
            <div class="d-flex text-left customlightgray my-border pa-0 h-100 ma-0">
                <v-row class="pa-0 mx-3 my-auto">
                  <v-col cols="4" md="2" class="pa-auto ma-0" >
                    <span class="d-flex pr-2 text--disabled text-capitalize">{{ $t('assignee') }}:</span>
                  </v-col>
                  <v-col v-if="update_assignee && $checkPermission('Tasks.Update') && task.type!=2" cols="8" md="10" class="pa-1 ma-0">
                          <v-autocomplete append-icon="mdi-close" append-outer-icon="mdi-check" @click:append="update_assignee = false,modify=false"
                           @click:append-outer="save('update_assignee')" clearable clear-icon="mdi-delete" v-model="editedTask.assigned_to" item-value="id" item-text="full_name"
                          :items="users" class="ma-0 pa-0 pr-2 white my-border" flat solo dense hide-details></v-autocomplete>
                  </v-col>
                  <v-col v-else cols="8" md="10" class="pa-auto ma-0">
                    <v-tooltip content-class='custom-tooltip' right>
                      <template  v-slot:activator="{ on }">
                        <label v-if="task.full_assigned" v-on="on" @click="update_assignee = true,modify=true" class="clickable text-capitalize">
                          {{ task.full_assigned.first_name + " " + task.full_assigned.last_name }}
                        </label>
                        <label v-else v-on="on" @click="update_assignee = true,modify=true" class="clickable ">
                          -------------
                        </label>
                      </template>
                      <v-btn  v-if="$checkPermission('Tasks.Update') && task.type!=2" @click="update_assignee = true,modify=true" icon color="teal">
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </v-tooltip>
                  </v-col>
                  <v-spacer></v-spacer>
                </v-row>
            </div>
                  
          </v-col>
          <v-col cols="12" class="ma-0 pa-1 task-column">
            <div class="d-flex text-left customlightgray my-border pa-0 h-100 ma-0">
              <v-tooltip left v-if="editedTask.request_id">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn :to="{name: 'requests', params: {id: task.request_id}}" v-on='on' v-bind="attrs" class="redirecttoreq" icon><v-icon>mdi-open-in-new</v-icon></v-btn>
                </template>
                <span>{{$t("redirect")}}</span>
              </v-tooltip>
              <v-row class="pa-0 mx-3 my-auto">
                <v-col cols="4" md="2" class="pa-auto ma-0" >
                  <span class="d-flex pr-2 text--disabled text-capitalize">{{ $t('request') }}:</span>
                </v-col>
                <v-col v-if="update_request && $checkPermission('Tasks.Update') && task.type!=2" cols="8" md="10" class="pa-1 ma-0">
                  <v-autocomplete append-icon="mdi-close" append-outer-icon="mdi-check" @click:append="update_request = false,modify=false"
                        @click:append-outer="save('update_request')" clearable clear-icon="mdi-delete" v-model="editedTask.request_id" item-value="id" item-text="reqCode"
                        :items="requests" class="ma-0 pa-0 pr-2 white my-border" flat solo dense hide-details></v-autocomplete>
                </v-col>
                <v-col v-else cols="8" md="10" class="pa-auto ma-0">
                  <v-tooltip content-class='custom-tooltip' right>
                    <template  v-slot:activator="{ on }">
                      <label v-if="task.request_id" v-on="on" @click="update_request = true,modify=true" class="clickable ">
                        {{ getRequestCode(task.request_id) }}
                      </label>
                      <label v-else v-on="on" @click="update_request = true,modify=true" class="clickable ">
                        ------
                      </label>
                    </template>
                    <v-btn  v-if="$checkPermission('Tasks.Update') && task.type!=2" @click="update_request= true,modify=true" icon color="teal">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </v-tooltip>
                </v-col>
                <v-spacer></v-spacer>
              </v-row>
            </div>
          </v-col>
          <v-col cols="12" class="ma-0 pa-1 task-column">
            <div class="d-flex text-left customlightgray my-border pa-0 h-100 ma-0">
              <v-row class="pa-0 mx-3 my-auto">
                <v-col cols="4" md="2" class="pa-auto ma-0">
                  <span class="d-flex pr-2 text--disabled text-capitalize">{{ $t('description') }}: </span>
                </v-col>
                <v-col cols="8" md="10" v-if="update_description && $checkPermission('Tasks.Update') && task.type!=2" class="pa-1 ma-0">
                    <v-textarea clearable clear-icon="mdi-delete" append-icon="mdi-close" append-outer-icon="mdi-check" @click:append="update_description = false,modify=false"
                    @click:append-outer="save('update_description')" v-model="editedTask.description" rows="2" auto-grow hide-details dense class="ma-0 pa-0 pr-2 white my-border" flat solo></v-textarea>
                </v-col>
                <v-col cols="8" md="10" v-else class="pa-auto ma-0">
                    <v-tooltip content-class='custom-tooltip' right>
                      <template v-slot:activator="{ on }">
                        <label v-if="editedTask.description" v-on="on" @click="modify = true , update_description=true" class="clickable ">
                          {{ task.description }}   
                        </label>
                        <label v-else v-on="on" @click="modify = true , update_description=true" class="clickable ">
                          -------------
                        </label>
                      </template>
                      <v-btn v-if="$checkPermission('Tasks.Update') && task.type!=2" @click="modify = true , update_description=true" icon color="teal">
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </v-tooltip>
                </v-col>
                <v-spacer></v-spacer>
              </v-row>
            </div>
          </v-col>
        </v-row>
        <taskAttachements :task="task"></taskAttachements>
        <subTasks @setTask="setTask" :task="task" :isopen="isopen"></subTasks>
        <v-row class="pa-0 ma-0 mt-4" v-if="$checkPermission('Tasks.Approve') && task.type==2">
          <v-col cols="12" sm="10" lg="11" order="2" order-sm="1" class="pa-0 ma-0">
            <v-textarea
              grow
              outlined
              class="white"
              hide-details
              v-model="reason"
              :placeholder="$t('Reason')"
            ></v-textarea>
          </v-col>
          <v-col cols="12" sm="2" lg="1" order="1" order-sm="2" class="pa-0 pl-sm-1 pl-0 ma-auto text-center text-sm-right ">
            <v-btn class="ma-0 pa-0 custom-color-accent" style="width:100%;" depressed @click="approve"> 
                {{$t('approve')}} 
            </v-btn>
            <v-btn class="ma-0 my-1 pa-0" style="width:100%;" dark depressed @click="reject">
                {{$t('Reject')}}
            </v-btn>
          </v-col>
        </v-row>
        <taskTimeline :task="task"></taskTimeline>
      </div>
       <v-dialog v-model="dialogClose" max-width="600px">
        <v-card class="customoffwhite">
          <v-card-title class="justify-center ma-0 customlightgray">
            <span class="font-weight-light">{{$t('setdateclosed')}}</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="ma-0 pa-0">
            <v-container class="ma-0">
              <v-menu
                v-model="closed"
                :close-on-content-click="true"
                :nudge-right="40"
                class="ma-0 pa-0"
                hide-details
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    dense
                    v-model="editedTask.date_completed"
                    outlined
                    :label="$t('Date Closed')"
                    :placeholder="$t('Date Closed')"
                    prepend-inner-icon="mdi-calendar"
                    class="ma-0 pa-0 white"
                    v-on="on"
                    hide-details
                  ></v-text-field>
                </template>
                <v-date-picker dark :max="nowDate" v-model="editedTask.date_completed" @input="closed = false"></v-date-picker>
              </v-menu> 
              </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="custom-color-accent-text" text @click="dialogClose = false,editedTask.date_completed=null">{{$t('cancel')}}</v-btn>
            <v-btn class="custom-color-accent-text" text @click="closeTask">{{$t('save')}}</v-btn>
          </v-card-actions>

        </v-card>
       </v-dialog>  
    </v-card>
</template>
<script>
import taskAttachements from "@/components/task/TaskAttach";
import subTasks from "@/components/task/SubTasks";
import taskTimeline from "@/components/task/TaskTimeline";
import {mapActions, mapState} from "vuex";
import moment from "moment";
import approveoverlay from '@/components/task/approveTaskOverlay.vue'
export default {
  components:{
    taskAttachements,
    subTasks,
    approveoverlay,
    taskTimeline,
  },
  async created() {
    this.$store.dispatch('task/retriveTasks').then(()=>{
      this.$store.dispatch('attachment/retriveAttachments').then(()=>{
        this.currentTask();
      })
    });
    Object.assign(this.editedTask, this.task);
    this.due_date = moment(this.editedTask.due_date).format("YYYY-MM-DD");
  },
  data() {
    return {
      task:'',
      reason: "",
      due:false,
      closed:false,
      dialogClose:false,
      editedTask:{},
      nowDate: new moment().toISOString(),
      modify: false,
      update_date_due:false,
      update_description:false,
      update_assignee:false,
      update_request:false,
      isopen:true,
      due_date:null
    }
  },
  computed:{
    ...mapState('task',{
      tasks: "tasks"
    }),
    ...mapState('attachment',{
      attachments:'attachments'
    }),
    ...mapState({
      users: "users"}),
    ...mapState("configuration", {
      configurations: "configurations"
    }),
     ...mapState(['user']),
     ...mapState('request',{
      'requests':'requests'
    }),
  },
  watch:{
    tasks(){
      this.currentTask();
    },
    attachments(){
      this.currentTask();
    },
    task(val){
      this.editedTask={};
      Object.assign(this.editedTask, val);
      this.due_date = moment(this.editedTask.due_date).format("YYYY-MM-DD");
    },
    modify(val){
      if(val){
        if(this.$checkPermission("Tasks.Update") && this.task.type!=2){
          Object.assign(this.editedTask, this.task);
        }
        else{
          this.$root.$emit("callAlert", {
            text: this.$t("notallowed"),
            type: "dark",
            alert: true
          });
          this.modify=false;
        }
      }
    }
  },
  methods:{
    formatDate(date){
      return moment(date).format('YYYY-MM-DD')
    },
    getUser(id){
      return this.users.filter(i => i.id === id ).map(j=>j.first_name+' '+j.last_name)[0];
    },
    save(obj) {
    if(this.editedTask.assigned_to==undefined)
      this.editedTask.assigned_to=null;
    if(this.editedTask.request_id==undefined)
      this.editedTask.request_id=null;
    let log_en='';
    let log_fr='';
    let fulluser=null;
    if(this.editedTask.assigned_to!=this.task.assigned_to && this.editedTask.assigned_to){
      fulluser = this.getUser(this.editedTask.assigned_to)
      log_en="Has Assigned This Task To: "+fulluser;
      log_fr="A assigné cette tâche à : "+fulluser;
    }
    else if(this.editedTask.assigned_to!=this.task.assigned_to && this.editedTask.assigned_to==null){
      fulluser = this.getUser(this.task.assigned_to)
      log_en="Has Unassigned "+fulluser;
      log_fr="A désassigné "+fulluser;
    }
      this.editedTask.due_date = this.due_date;
    this.updateTask(this.editedTask).then(() => {
      if(log_en!='' && log_fr!='' && fulluser)
        this.postTaskLog({ task_id: this.editedTask.id, log_en: log_en,log_fr:log_fr, user_id: this.user.id})
      let item = {text:this.$t("taskUpdateAlert"), type:"dark", alert:true};
      this.$root.$emit("callAlert", item);
      this.modify = false;
      switch(obj){
        case 'update_date_due':
          this.update_date_due = false;
          break;
        case 'update_assignee':
          this.update_assignee = false;
          break;
        case 'update_request':
          this.update_request = false;
          break;
        case 'update_description':
          this.update_description = false;
          break;
      }
      });
    },
      approve(){
      this.task.type=3;
      this.updateTask(this.task).then(() => {
      this.$root.$emit("callAlert", {text: this.$t('taskapproved'), type: "success", alert: true });
      let rs =this.reason!=""?" Reason: '"+this.reason+"'":"";
      let rsfr =this.reason!=""?" Raison: '"+this.reason+"'":"";
      let log_en="Has Approved The Task."+rs
      let log_fr="A approuvé la tâche."+rsfr
      this.postTaskLog({ task_id: this.task.id, log_en: log_en,log_fr:log_fr, user_id: this.user.id})
       });
    //  this.approved=true;
    },
    reject(){
      this.task.type=4;
       this.task.status= 0;
       this.task.date_completed= moment().format("YYYY-MM-DD HH:mm:ss");
      this.updateTask(this.task).then(() => {
      this.$root.$emit("callAlert", {text: this.$t('taskdenied'), type: "dark", alert: true });
      let rs =this.reason!=""?" Reason: '"+this.reason+"'":"";
      let rsfr =this.reason!=""?" Raison: '"+this.reason+"'":"";
      let log_en="Has denied The Task."+rs
      let log_fr="A refusé la tâche."+rsfr
      this.postTaskLog({ task_id: this.task.id, log_en: log_en,log_fr:log_fr, user_id: this.user.id})
       });
    //  this.approved=true;
    },

    getRequestCode(id){
      return this.requests.filter(r=>r.id === id).map(a=>a.reqCode)[0]
    },
    close(){
      this.dialogClose=true;
      this.editedTask.date_completed=moment().format("YYYY-MM-DD HH:mm:ss");
    },
    closeTask(){
      this.updateTask({id:this.task.id, status: 0, date_completed: this.editedTask.date_completed}).then(()=>{
        this.$root.$emit("callAlert", {text: this.$t('taskclosed'), type: "dark", alert: true });
        let log_en="Has Closed This Task.";
        let log_fr="A fermé cette tâche.";
        this.dialogClose=false;
        this.postTaskLog({ task_id: this.task.id, log_en: log_en,log_fr:log_fr, user_id: this.user.id})
      })
    },
    reopenTask(){
      this.updateTask({id:this.task.id, status: 1, date_completed:''}).then(()=>{
        this.$root.$emit("callAlert", {text: this.$t('taskreopened'), type: "dark", alert: true });
        let log_en="Has Reopened This Task.";
        let log_fr="A rouvert cette tâche.";
        this.postTaskLog({ task_id: this.task.id, log_en: log_en,log_fr:log_fr, user_id: this.user.id})
      })
    },
   getLabelLookup(column = 'TaskLabel', label){
     
      return this.configurations
        .filter(filter => filter.LookUpName === column && filter.LookUpKey === label.toString())
        .map(item => {
          
            return localStorage.getItem("language") === "en"
              ? item.LookUpStringE
              : item.LookUpStringF;
          
        });
    },
      setcolor(status) {
        if (status === "done") {
          return "success";
        } else if (status === "onHold") {
              return "warning";
        } else if (status === "ongoing") {
          return "primary";
        } else if (status === "todo") {
          return "gray";
        } else if (status === "high") {
          return "error";
        } else if (status === "medium") {
          return "warning";
        } else if (status === "low") {
          return "gray";
        } else{
          return "gray"
        }
      },
      currentTask(){
        this.task = this.tasks.filter( f => f.id === parseInt(this.$route.params.id) )[0] ?? [];
      },
      setTask(id){
        this.task = this.tasks.filter( f => f.id === id )[0] ?? [];
        if( parseInt(this.$route.params.id) !== id)
        this.$router.push({ name: "tasks", params: {id: id} });
      },
    ...mapActions('task',['updateTask',"postTaskLog"]),
  }
}
</script>
<style lang="scss" scoped>
.task-column{
  position: relative;
  min-height: 75px;
}
.h-100{
    height: 100%;
}
.text-wrap{
    word-break: break-word;
}
.custom-tooltip {

  background: rgba(0, 0, 0, 0);
  pointer-events: auto;
}
.redirecttoreq{
  position:absolute;
  top:5px;
  right:5px;
}
</style>